//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatisticsTradeTurnoverP2P from './StatisticsTradeTurnoverP2P/StatisticsTradeTurnoverP2P.vue';

export default {
  components: { StatisticsTradeTurnoverP2P },
};
