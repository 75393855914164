//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        text: null,
        CreatedAtLower: null,
        CreatedAtUpper: null,
        userTagsInclude: [],
        equivalentCurrencyId: 2,
      },
      constant,
    };
  },

  computed: {
    typesFeedback: () => constant.p2p.FEEDBACK_INFLUENCE_LIST,
    ...mapGetters('currencies', ['getCurrencyByTitle']),
    ...mapState('users', ['userTagsList']),

    priceEquivalentList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('statisticsTradeTurnover', {
      loadDataAction: 'loadData',
      setFilters: 'setFilters',
    }),
  },
};
