export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Turnover volume',
    },
    cell: {
      type: 'slot',
      name: 'volume',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Fee',
    },
    cell: {
      type: 'slot',
      name: 'fee',
    },
  },
];
