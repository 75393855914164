//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex';
import constant from '~/const';
import tableConfig from './tableConfig';
import StatisticsTradeTurnoverP2PFilters from './StatisticsTradeTurnoverP2PFilters.vue';
import StatisticsTradeTurnoverP2PChart from './StatisticsTradeTurnoverP2PChart.vue';

export default {
  components: {
    StatisticsTradeTurnoverP2PFilters,
    StatisticsTradeTurnoverP2PChart,
  },
  data() {
    return {
      constant,
      tableConfig,
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('statisticsTradeTurnover', ['amounts', 'chart', 'currentCurrencyId']),

    isCurrentCurrencyAllowed() {
      return this.currentCurrencyId > 0;
    },
  },

  async created() {
    this.setGeneralProgress(true);
    this.loader = true;
    await Promise.allSettled([
      this.loadData(),
    ]);
    this.loader = false;
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('statisticsTradeTurnover', {
      loadChartAction: 'loadChart',
      loadDataAction: 'loadData',
    }),
    ...mapMutations('statisticsTradeTurnover', {
      setCurrentCurrencyId: 'SET_CURRENT_CURRENCY_ID',
    }),
    async setCurrentCurrency({ currencyId }) {
      this.setCurrentCurrencyId(currencyId);
      await this.loadChart();
    },
    async loadData() {
      await this.loadDataAction();
    },
    async loadChart() {
      await this.loadChartAction();
    },
    async applyFilters() {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction();
      await this.loadChartAction();
      this.setGeneralProgress(false);
      this.loader = false;
    },
  },
};
